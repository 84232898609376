import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { green, red } from "@mui/material/colors";
import data from "../Data"
import { Select } from "@mui/material";


const useValidation = (value, validations) => {
    const [isEmpty, setEmpty] = useState(true)
    const [minLengthError, setMinLengthError] = useState(false)
    const [maxLengthError, setMaxLengthError] = useState(false)
    const [telError, setTelError] = useState(true)
    const [nameError, setNameError] = useState(true)
    const [inputValidName, setInputValidName] = useState(false)
    const [inputValidTel, setInputValidTel] = useState(false)




    useEffect(() => {
        for (const validation in validations) {
            switch (validation) {
                case 'minLength':
                    value.length < validations[validation] ? setMinLengthError(true) : setMinLengthError(false)
                    break;
                case 'isEmpty':
                    value ? setEmpty(false) : setEmpty(true)
                    break;
                case 'maxLength':
                    value.length > validations[validation] ? setMaxLengthError(true) : setMaxLengthError(false)
                    break;
                case 'telError':
                    const check = /^(\+|0)[\d\(\)\ -]{8,20}\d$/.test(value);
                    check ? setTelError(false) : setTelError(true)
                    break;
                case 'nameError':
                    const ername = /^[A-ZȘȚĂÎÂa-zăîșțâ\s&]*$/.test(value);
                    ername ? setNameError(false) : setNameError(true)
                    break;


            }

        }
    }, [value])

    useEffect(() => {
        if (isEmpty || minLengthError || nameError) {
            setInputValidName(false)
        } else {
            setInputValidName(true)
        }
    }, [isEmpty, minLengthError, nameError])


    useEffect(() => {
        if (isEmpty || minLengthError || maxLengthError || telError) {
            setInputValidTel(false)
        } else {
            setInputValidTel(true)
        }
    }, [isEmpty, minLengthError, maxLengthError, telError])




    return {
        isEmpty,
        minLengthError,
        maxLengthError,
        telError,
        nameError,
        inputValidName,
        inputValidTel
    }


};

const useInput = (initialValue, validations) => {
    const [value, setValue] = useState(initialValue);
    const [isDirty, setDirty] = useState(false);
    const valid = useValidation(value, validations)

    const onChange = (e) => {
        setValue(e.target.value)
    }

    const onBlur = (e) => {
        setDirty(true)
    }


    return {
        value,
        onChange,
        onBlur,
        isDirty,
        ...valid

    }

}


const FormValidation = (props) => {
    const name = useInput('', { isEmpty: true, minLength: 3, nameError: true });
    const phone = useInput('', { isEmpty: true, minLength: 9, maxLength: 20, telError: true })
    const tipizolare = useInput('mansarda')
    const suprafata = useInput('')
    const locatia = useInput('')
    const message = useInput('')

    const [success, setSuccess] = useState(false);

    const onSubmit = () => {
        Swal.fire("Expediat cu SUCCES!", "Vă mulțumim, cererea a fost expediată!", "success");
        setSuccess(true);
        setTimeout(function () {
            window.location.reload(1);
        }, 4000);
    }


    return (
        <>

            <form className="forms  container text-center">

                {data.introData.map((item) => {
                    return (

                        <div>
                            <input type="hidden" name="project_name" value="EcoizolareMD" />
                            <input type="hidden" name="admin_email" value={item.email} />
                            <input type="hidden" name="form_subject" value="Cerere de oferta" />
                        </div>


                    )
                })}






                <div className="row justify-content-center text-center">
                    <div className="col-md-4 m-2 p-2 ">
                        <label className="form-label">
                            * Nume:
                        </label>
                        <input className="form-control"
                            onChange={e => name.onChange(e)}
                            onBlur={e => name.onBlur(e)}
                            name="numele"
                            value={name.value}
                            type="text"
                            placeholder="Alexandru"
                        />
                        {(name.isDirty && name.isEmpty) && <div style={{ color: 'red' }}>Introduceți numele dvs!</div>}
                        {(name.isDirty && name.minLengthError) && <div style={{ color: 'orange' }}>Numele nu poate conține mai puțin de trei caractere!</div>}
                        {(name.isDirty && name.nameError) && <div style={{ color: 'red' }}>Numele dvs nu poate conține simboluri (@, #, %...) sau cifre (0-9)!</div>}
                    </div>
                    <div className="col-md-4 m-2 p-2">
                        <label className="form-label"> * Număr de contact:</label>
                        <input className="form-control"
                            name="nr. de contact"
                            onChange={e => phone.onChange(e)}
                            onBlur={e => phone.onBlur(e)}
                            value={phone.value}
                            type="tel"
                            placeholder="ex. +373 428 71 240"
                        />
                        {(phone.isDirty && phone.isEmpty) && <div style={{ color: 'red' }}>Introduceți numărul dvs de contact!</div>}
                        {(phone.isDirty && phone.telError) && <div style={{ color: 'red' }}>Introduceți numărul de contact în formatul +(codul țării) XXX XX XXX, la fe numarul nu poate conține mai puțin de 8 cifre!</div>}
                    </div>
                </div>

                <hr />

                <div className="row justify-content-center text-center">
                    <div className="col-md-4">
                        <label className="form-label px-2">
                            Suprafața (m<sup>2</sup>):</label>
                        <input id="suprafata"
                            className="form-control"
                            type="number"
                            placeholder="ex. 200"
                            name="suprafata"
                            min="0" max="2000"
                            value={suprafata.value}
                            onChange={e => suprafata.onChange(e)}
                            onBlur={e => suprafata.onBlur(e)}
                        />
                    </div>
                    <div className="col-md-4">
                        <label className="form-label px-2">
                            Locatia obiectului:</label>
                        <select id="locatia"
                            className="form-control"
                            name="locatia"
                            value={locatia.value}
                            onChange={e => locatia.onChange(e)}
                            onBlur={e => locatia.onBlur(e)}
                        >
                            <option value='--'>--Selectati locatia--</option>
                            <option value='Municipiul Chișinău'>Municipiul Chișinău</option>
                            <option value='Municipiul Bălți'>Municipiul Bălți</option>
                            <option value='Municipiul Bender'>Municipiul Bender</option>
                            <option value='Anenii Noi'>Anenii Noi</option>
                            <option value='Basarabeasca'>Basarabeasca</option>
                            <option value='Briceni'>Briceni</option>
                            <option value='Cahul'>Cahul</option>
                            <option value='Camenca'>Camenca</option>
                            <option value='Călărași'>Călărași</option>
                            <option value='Cantemir'>Cantemir</option>
                            <option value='Căușeni'>Căușeni</option>
                            <option value='Cimișlia'>Cimișlia</option>
                            <option value='Criuleni'>Criuleni</option>
                            <option value='Dondușeni'>Dondușeni</option>
                            <option value='Drochia'>Drochia</option>
                            <option value='Dubăsari'>Dubăsari</option>
                            <option value='Edineț'>Edineț</option>
                            <option value='Fălești'>Fălești</option>
                            <option value='Florești'>Florești</option>
                            <option value='Glodeni'>Glodeni</option>
                            <option value='Hîncești'>Hîncești</option>
                            <option value='Ialoveni'>Ialoveni</option>
                            <option value='Leova'>Leova</option>
                            <option value='Nisporeni'>Nisporeni</option>
                            <option value='Ocnița'>Ocnița</option>
                            <option value='Orhei'>Orhei</option>
                            <option value='Rezina'>Rezina</option>
                            <option value='Rîșcani'>Rîșcani</option>
                            <option value='Sîngerei'>Sîngerei</option>
                            <option value='Soroca'>Soroca</option>
                            <option value='Strășeni'>Strășeni</option>
                            <option value='Șoldănești'>Șoldănești</option>
                            <option value='Ștefan Vodă'>Ștefan Vodă</option>
                            <option value='Taraclia'>Taraclia</option>
                            <option value='Telenești'>Telenești</option>
                            <option value='Ungheni'>Ungheni</option>
                            <option value='UTA Găgăuzia'>UTA Găgăuzia</option>
                            <option value='UTA din Stînga Nistrului'>UTA din Stînga Nistrului</option>
                        </select>
                    </div>
                </div>


                <hr />

                <div className="row justify-content-center ">


                    <div className="col-md-4 col-lg-4 col-8 form-yes mx-4">
                        <label className="form-check-label"> * Ce doriti sa izolati?:</label>

                        <div className="col-12 m-2">
                            <input className="form-check-input"
                                type="radio"
                                name="ce izolam?"
                                value="mansarda"
                                checked={tipizolare.value === "mansarda"}
                                onChange={e => tipizolare.onChange(e)}
                                onBlur={e => tipizolare.onBlur(e)}
                            />
                            <label className="" style={{ color: green[500], fontSize: '20px' }}> mansarda</label>
                        </div>


                        <div className="col-12 m-2 text-left ">
                            <input className="form-check-input"
                                type="radio"
                                name="ce izolam?"
                                value="tavan"
                                checked={tipizolare.value === "tavan"}
                                onChange={e => tipizolare.onChange(e)}
                                onBlur={e => tipizolare.onBlur(e)}
                            />
                            <label className="" style={{ color: red[500], fontSize: '20px' }}> tavan</label>

                        </div>

                        <div className="col-12 m-2">
                            <input className="form-check-input"
                                type="radio"
                                name="ce izolam?"
                                value="pod"
                                checked={tipizolare.value === "pod"}
                                onChange={e => tipizolare.onChange(e)}
                                onBlur={e => tipizolare.onBlur(e)}
                            />
                            <label className="" style={{ color: red[500], fontSize: '20px' }}>pod</label>

                        </div>

                        <div className="col-12 m-2">
                            <input className="form-check-input"
                                type="radio"
                                name="ce izolam?"
                                value="pereti"
                                checked={tipizolare.value === "pereti"}
                                onChange={e => tipizolare.onChange(e)}
                                onBlur={e => tipizolare.onBlur(e)}
                            />
                            <label className="" style={{ color: red[500], fontSize: '20px' }}>pereti</label>

                        </div>


                    </div>

                    <div className="col-md-4 col-lg-4 col-8  form-no mx-4 ">

                        <div className="row row-no">

                            <div className="col-12 p-2">
                                <label className="form-label m-2 px-2">
                                    Lăsați un mesaj sau detalii:</label>
                                <textarea
                                    name="mesaj"
                                    value={message.value}
                                    onChange={e => message.onChange(e)}
                                    onBlur={e => message.onBlur(e)}
                                />

                            </div>

                        </div>

                    </div>


                </div>





                <hr />

                <div>
                    <button disabled={!name.inputValidName || !phone.inputValidTel} onClick={onSubmit} type="submit" data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-submit  mb-3 p-3">Vreau Oferta</button>
                </div>



            </form>

        </>
    );

}

export default FormValidation;