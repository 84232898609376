import { fontSize } from '@mui/system';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../css/Beneficii.css'
import img1 from '../images/icon ecoizolare/economie.png'
import img2 from '../images/icon ecoizolare/rapid.png'
import img3 from '../images/icon ecoizolare/echipa.png'
import img4 from '../images/icon ecoizolare/termo.png'
import img5 from '../images/icon ecoizolare/ecom.png'


const Beneficii = () => {
  let data = [
      {
          id: 1,
          imgSrc: img1,
          date: 'Economie -35%',
          title: 'Facturi mai mici la energie',
          descripson: 'Izolația cu Fulgi de Celuloză reduce costurile consumului de energie și de încălzire în timpul iernii cu până la 35%,  ceea ce înseamnă facturi mai mici.'
        },
        {
          id: 2,
          imgSrc: img2,
          date: 'Rapid 1-3 zile',
          title: 'Montarea rapidă',
          descripson: 'Montarea se efectuează prin pompare din exterior ușor și rapid cu ajutorul unui utlaj performant, practic fără creare de deșeuri. Nu facem DELOC mizerie în casă sau în curte și terminăm lucrarea în câteva ore.'
        }, 
         {
          id: 3,
          imgSrc: img4,
          date: 'Explotare 90 ani',
          title: 'Efeciență termică și fonică',
          descripson: 'Termenul de explotare peste 90 ani, datorită că este un material ecologic. Are proprietatea de a păstra căldura din casa iarna și mai răcoroasă vara fără echipamente scumpe de ventilație. Celuloza asigură în același timp o izolație fonică perfectă.'
        },
        {
          id: 4,
          imgSrc: img3,
          date: '12 ani in R.Moldova',
          title: 'Echipă de profesioniști',
          descripson: 'Executăm lucrările de izolare la cel mai înalt nivel și o experință de peste 12 ani în R. Moldova. Garanție de peste 10 ani la lucrările executate. Folosim utilaj de ultima generație.'
        },
      
        {
          id: 5,
          imgSrc: img5,
          date: 'Ecologoic 100%',
          title: 'Izolație naturală',
          descripson: 'Celuloza este un material termoizolant din fibre produse printr-o procedură specială de reciclare din maculatură (hârtie reciclată) care se taie la dimensiunea de câțiva milimetri și se tratează cu anumite substanțe pentru a-i da rezistență la foc, dăunători, rozătoare și mucegai.'
        },
  ]
  return (
      <>
<VerticalTimeline
lineColor='#01AC21'
>
{data.map((item, index) => {
                  return (
                         
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#01AC21', color: 'white'}}
    contentArrowStyle={{ borderRight: '7px solid  #01AC21' }}
    date={item.date}
    dateClassName='vertical-timeline-element--date'
    iconClassName='vertical-timeline-element--icon'
    iconStyle={{ background: '#01AC21'}}
    icon={ <img src={item.imgSrc}/>}
  >
    <h3 className="vertical-timeline-element-title" style={{color: 'white'}}>{item.title}</h3>
    <p>
    {item.descripson}
    </p>
  </VerticalTimelineElement>
)
})}
  </VerticalTimeline>
      </>
  );
}

export default Beneficii;    


                      
                  