import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "../css/Styles.css";


// import required modules
import { Autoplay, EffectCoverflow, Pagination } from "swiper";


import img1 from '../images/recenzii/r1.jpg';
import img2 from '../images/recenzii/r2.jpg';
import img3 from '../images/recenzii/r3.jpg';
import img4 from '../images/recenzii/r4.jpg';
import img5 from '../images/recenzii/r5.jpg';
import img6 from '../images/recenzii/r6.jpg';
import img7 from '../images/recenzii/r7.jpg';

const Slide = () => {
    let data = [
        {
            id: 1,
            imgSrc: img1,
            localitatea: 'or. Balti',
            name: 'Igor P.',
            descripsion: 'O echipa perfecta!',
        },
        {
            id: 2,
            imgSrc: img2,
            localitatea: 'or. Chisinau',
            name: 'Radu L.',
            descripsion: 'Suntem foarte multumiti ca am apelat la asa o echipa',
        },
        {
            id: 3,
            imgSrc: img3,
            localitatea: 'or. Hancesti',
            name: 'Vlad C.',
            descripsion: 'O izolare perfecta',
        },
        {
            id: 4,
            imgSrc: img4,
            localitatea: 'or. Balti',
            name: 'Igor P.',
            descripsion: 'O echipa perfecta!',
        },
        {
            id: 5,
            imgSrc: img5,
            localitatea: 'or. Chisinau',
            name: 'Radu L.',
            descripsion: 'Suntem foarte multumiti ca am apelat la asa o echipa',
        },
        {
            id: 6,
            imgSrc: img6,
            localitatea: 'or. Hancesti',
            name: 'Vlad C.',
            descripsion: 'O izolare perfecta',
        },

    ]
    return (
        <>

            <div className="slider mb-4 pb-4">
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={true}
                    modules={[Autoplay, EffectCoverflow, Pagination]}
                    className="mySwiper"
                >
                    {data.map((item, index) => {
                        return (<>
                       

                            <SwiperSlide>
                                
 <div className="intro__media">
                                    <img src={item.imgSrc} />
                                    </div>
                                   
                                    <div className="intro__content" 
                                    style={{background: 'green', width: '100%', marginTop:"100%",}}>
                                        <h4>{item.localitatea}</h4>
                                        <h5>{item.name}</h5>
                                        <p>{item.descripsion}</p>
                                    </div>
                              

                               

                            </SwiperSlide>


                    </>
                        )
                    })}

                </Swiper></div>
        </>
    );
}

export default Slide;
