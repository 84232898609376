
import React from "react";
import '../css/Contacte.css'
import data from "../Data"


const ButtonContact = () => {
 
  return (
    <>
   
   {data.introData.map((item) => { 
        return(
        <>  
          <a href={item.phone} target="_blank" rel="nofollow">
    <span class="phone-button contact-button"></span>
</a> 
<a href={item.viber} target="_blank" rel="nofollow">
    <span class="viber-button contact-button"></span>
</a>
<a href={item.whatsapp} target="_blank" rel="nofollow">
    <span class="whatsapp-button contact-button"></span>
</a> 
<a href={item.messenger} target="_blank" rel="nofollow">
    <span class="telegram-button contact-button"></span>
</a> 
<a href="#header"  rel="nofollow">
    <span class="arrow-button contact-button"></span>
</a> 
</>)
})}
        </>
  
  )}

  export default ButtonContact;