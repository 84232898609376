import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@mui/material/IconButton';

import {
  EmailIcon,
  FacebookIcon,
  FacebookShareButton,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  MailruIcon,
  OKIcon,
  TelegramIcon,
  TwitterIcon,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { Typography } from '@material-ui/core';

export default function ShareButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const shareUrl = 'http://ecoizolare.md/'
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ShareIcon />
      </IconButton>
      <Menu 
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleClose} style={{padding: '2px', margin: '5px'}}>
          <FacebookShareButton url={shareUrl}>
            <FacebookIcon size={32} round={true}/>
            <span> Facebook</span>
          </FacebookShareButton>
          </MenuItem> <br/> 
        <MenuItem onClick={handleClose} style={{padding: '2px', margin: '5px'}}>
        <FacebookMessengerShareButton url={shareUrl}>
            <FacebookMessengerIcon size={32} round={true} />
            <span> Messenger</span>
          </FacebookMessengerShareButton>
          </MenuItem> <br/>
          <MenuItem onClick={handleClose} style={{padding: '2px', margin: '5px'}}>
        <ViberShareButton url={shareUrl}>
            <ViberIcon size={32} round={true} />
            <span> Viber</span>
          </ViberShareButton>
          </MenuItem> <br/>
          <MenuItem onClick={handleClose} style={{padding: '2px', margin: '5px'}}>
        <WhatsappShareButton url={shareUrl}>
            <WhatsappIcon size={32} round={true} />
            <span> Whatsapp</span>
          </WhatsappShareButton>
          </MenuItem>
      </Menu>
    </div>
  );
}
