import React from "react"
import background from "../images/header.jpg"
import Accordion from 'react-bootstrap/Accordion'


const FAQ = () => {
    return (

        <section id="faq" className="text-center  p-5" style={{ backgroundImage: `url(${background})` }}>
            <h1 className="pt-3" style={{color: 'white', textShadow: `3px 3px 5px black, 0 0 4em black, 0 0 3em pink` }}>ÎNTREBĂRI FRECVENTE</h1>
<div >


            <Accordion defaultActiveKey="0" className="container" style={{ maxWidth: '800px' }}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Ce fel de material sunt Fulgii de Celuloză?</Accordion.Header>
                    <Accordion.Body>
                    Celuloza este un material termoizolant din fibre produse printr-o procedură specială de reciclare din maculatură (hârtie reciclată) care se taie la dimensiunea de câțiva milimetri și se tratează cu anumite substanțe pentru a-i da rezistență la foc, dăunători, rozătoare și mucegai.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Cum se montează si termenul de montare?</Accordion.Header>
                    <Accordion.Body>
                        Direct flights to Chisinau Airport (KIV) go from DUS, DTM, HAM, BER, FMM, FRA and MLH. Timings are very different depending on each departing airport.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Termenul de explotare?</Accordion.Header>
                    <Accordion.Body>
                        If you can make it for the day of the party, that is already amazing. If given the flights that are available to you, you consider a trip for more than one day, that is even better. Just let us know what is possible for you. We will organize some activities and tours for all our guests who want to combine the party with a mini holiday. We will be there for a week, from Wednesday to Wednesday.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Cum pot afla prețul a unei izolații?</Accordion.Header>
                    <Accordion.Body>
                        We will help you organize a pick up at the airport if you want. For taxi services, generally hotel receptions, restaurants, etc. can help you out with that. If you visit from Solingen or Valparaiso, we would also suggest taking a “Troleibuz” /troh-ley-boose/  to feel a little like home.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Ce efeciență termică are?</Accordion.Header>
                    <Accordion.Body>
                        The local currency is LEU. 1€ equals approx. 20 lei. Exchange points are on every corner. You can also pay by card in most restaurants, bars, shops; although not in Taxis and small supermarkets.
                    </Accordion.Body>
                </Accordion.Item>
               
            </Accordion>
</div>
        </section>

    )
}

export default FAQ;