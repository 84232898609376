import React from "react"
import vid1 from "../video/videoreact1.mp4"
import '../css/Video.css';
import LikeIcon from '@mui/icons-material/ThumbUp';


const VideoAnimation = () => {
return (
    <div className="intro">
        <div className="intro__media">
            <video className="intro__media-video" src={vid1} autoPlay muted loop></video>
        </div>
        <div className="intro__content" style={{ maxWidth: '800px', padding: '30px' }}>
            <h2 style={{color: 'darkgreen'}}><LikeIcon sx={{ color: 'green', fontSize: 40}} /> Ecoizolare.MD</h2>
            <h4 >Izolația din fulgi de celuloză este injectată prin pompare. Datorită acestui mod de aplicare celuloza respectiv umple toate spațiile și ajunge în cele mai îndepărtate locuri și formează un strat monolit, compact de izolare, fără punți termice după aplicare.</h4>
        </div>
    </div>
)
}

export default VideoAnimation;