import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../css/SlideGallery.css";

// import required modules
import {Autoplay, Pagination, Navigation } from "swiper";


import img1 from '../images/obiecte/o1.jpg';
import img2 from '../images/obiecte/o2.jpg';
import img3 from '../images/obiecte/o3.jpg';
import img4 from '../images/obiecte/o4.jpg';
import img5 from '../images/obiecte/o5.jpg';
import img6 from '../images/obiecte/o6.jpg';
import img7 from '../images/obiecte/o7.jpg';
import img8 from '../images/obiecte/o8.jpg';
import img9 from '../images/obiecte/o9.jpg';
import img10 from '../images/obiecte/o10.jpg';



const SlideGallery = () => {
    let data = [
        {
            id: 1,
            imgSrc: img1,
        },
        {
            id: 2,
            imgSrc: img2,
        },
        {
            id: 3,
            imgSrc: img3,
        },
        {
            id: 4,
            imgSrc: img4,
        },

        {
            id: 5,
            imgSrc: img5,
        },
        {
            id: 6,
            imgSrc: img6,
        },
        {
            id: 7,
            imgSrc: img7,
        },
        

    ]

 
    return (
        <>

     <div className="slider mb-4 pb-4">
     <Swiper
        slidesPerView={window.innerWidth >= 500 ? 4 : 1}
        spaceBetween={30}
        slidesPerGroup={1}
        loop={true}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
         {data.map((item, index) => {
                        return (
                            <SwiperSlide>
                                <img src={item.imgSrc} />
                            </SwiperSlide>
                        )
                    })}
      </Swiper>
      </div>
        </>
    );
}

export default SlideGallery;