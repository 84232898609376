
import React from "react";
import data from "./Data.js"
import RecipeReviewCard from "./components/CardService"
const CardService = () => {
    return (
        <section id="service" className="card_service py-lg-5 container"  >
            <h2 className="text-center mt-5" >SERVICIILE NOASTRE</h2>
          

            <div className="row justify-content-center "  >

                {data.cardService.map((item, index) => {
                    return (
                        <RecipeReviewCard key={index} id={item.id} img={item.img} title={item.title} detalii={item.detalii}  />
                    )
                })}


            </div>
        </section>


    )


}

export default CardService;