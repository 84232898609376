import React, { Component } from "react";
import '../css/Header.css'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';

export default class Header extends Component {
    constructor () {
        super();
        this.state={
           swow: true,
        };

    }
    render () {
        return (
          <>
         
      <header className="fixed-top">

      <nav class="navbar navbar-light bg-success" >
 <div className="container px-2">
  <span><a className="navbar-contact px-2"  href={this.props.phone}> <PhoneIphoneIcon />{this.props.tel}</a> </span>
  <span><a className="navbar-contact px-2"  href={this.props.mailto}> <EmailIcon />{this.props.email}</a> </span>

</div> 
</nav>
  

        <nav class="navbar  navbar-expand-lg navbar-light  bg-light ">

  <div className="container-fluid px-5">
  <img id="logo" src={this.props.logo}/>
 
    <button className="navbar-toggler border text-success border-success" 
    onClick={()=>{this.setState({show: !this.state.show})}}>
      {this.state.show ?  <CloseIcon/> : <MenuIcon/>}
    </button>
    
    <div className={this.state.show ? 'collapse navbar-collapse  active bg-light text-center'  : 'collapse navbar-collapse text-center'}>
      <ul className="navbar-nav ms-auto">
        <li className="nav-item">
          <a className="nav-link text-success"  href="#avantaje">Avantajele izolarii</a>
        </li>
        <li className="nav-item nav_line">
          <a className="nav-link text-success"  href="">|</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-success" href="#service">Serviciile oferite</a>
        </li>
        <li className="nav-item nav_line">
          <a className="nav-link text-success"  href="">|</a>
        </li>
        <li className="nav-item ">
          <a className="nav-link text-success" href="#galerie">Galerie Foto-Video</a>
        </li>
        <li className="nav-item nav_line">
          <a className="nav-link text-success"  href="">|</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-success" href="#pret">Pret</a>
        </li>
        <li className="nav-item nav_line">
          <a className="nav-link text-success"  href="">|</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-success" href="#faq">Intrebari frecvente</a>
        </li>
        <li className="nav-item nav_line">
          <a className="nav-link text-success"  href="">|</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-success" href="#recenzii">Recenziile clientilor</a>
        </li>
        <li className="nav-item nav_line">
          <a className="nav-link text-success"  href="">|</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-success" href="#forms">Cere Oferta</a>
        </li>
      </ul>
    </div>
  </div>
</nav> 
        </header> 

</>
        )

 }}