
import React from 'react';
import Ratio from 'react-bootstrap/Ratio';


const GalerieVideo = () => {
  let data = [
    {
      id: 1,
      src: 'https://www.youtube.com/embed/R7_v6J0Zk2k',
    },
    {
      id: 2,
      src: 'https://www.youtube.com/embed/LgPMwUw6B_Y',
    },
   
  ]
  return (
    <>

      <section id="video_galerie" className="p-lg-5 container"  >

        <div className='container' >



          <h2 className='text-center m-3'>
            Video Galerie
          </h2>
          <hr style={{ width: '50%', background: 'white', height: '7px', margin: 'auto' }} />
          <div className='row p-3'>
            {data.map((item, index) => {
              return (
                <iframe className='col-6 mt-2' style={{height: '300px'}} src={item.src} title="YouTube video" allowfullscreen></iframe>
              )
            })}

          </div>
        </div>

      </section>


    </>
  );
}

export default GalerieVideo;